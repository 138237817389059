.row.mp-0 {
	margin-left: 0;
	margin-right: 0;
}
.row.mp-0 > [class^="col-"] {
	padding-left: 0;
	padding-right: 0;
}
.row.mp-0_5 {
	margin-left: -5px;
	margin-right: -5px;
}
.row.mp-0_5 > [class^="col-"] {
	padding-left: 5px;
	padding-right: 5px;
}
.row.mp-2 {
	margin-left: -20px;
	margin-right: -20px;
}
.row.mp-2 > [class^="col-"] {
	padding-left: 20px;
	padding-right: 20px;
}
.row.mp-2_5 {
	margin-left: -25px;
	margin-right: -25px;
}
.row.mp-2_5 > [class^="col-"] {
	padding-left: 25px;
	padding-right: 25px;
}
.row.mp-3 {
	margin-left: -30px;
	margin-right: -30px;
}
.row.mp-3 > [class^="col-"] {
	padding-left: 30px;
	padding-right: 30px;
}
.row.mp-3_5 {
	margin-left: -35px;
	margin-right: -35px;
}
.row.mp-3_5 > [class^="col-"] {
	padding-left: 35px;
	padding-right: 35px;
}
.row.mp-4 {
	margin-left: -40px;
	margin-right: -40px;
}
.row.mp-4 > [class^="col-"] {
	padding-left: 40px;
	padding-right: 40px;
}
.row.mp-4_5 {
	margin-left: -45px;
	margin-right: -45px;
}
.row.mp-4_5 > [class^="col-"] {
	padding-left: 45px;
	padding-right: 45px;
}
.row.mp-5 {
	margin-left: -50px;
	margin-right: -50px;
}
.row.mp-5 > [class^="col-"] {
	padding-left: 50px;
	padding-right: 50px;
}

button:focus,
button:active {
  outline: 0 solid !important;
  box-shadow: 0 0 0 0 !important;
}
.c-main .container-fluid {
  min-height: 100%;
}

.preloader-area:not(.absolute) {
  height: calc(100vh - 220px);
}

.page-wrapper table tbody {
  border-bottom: 1px solid #d8dbe0;
}

.preloader-area.absolute {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}

.custom-alert-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  z-index: 10000;
}

div#__react-alert__ > div {
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 15000 !important;
  display: block !important;
}

div#__react-alert__ > div > div {
  width: 100% !important;
  height: 100% !important;
}

.custom-alert-box-area {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 16000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  text-align: center;
}

.custom-alert-box,
.react-confirm-alert-body {
  background: rgba(0, 0, 0, 0.7);
  z-index: 18000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  color: #fff;
  width: 20em;
  max-width: 100%;
  padding: 1.25em;
  border-radius: 0;
}

.custom-alert-box .icon {
  width: 50px;
  height: 50px;
  background: #ecdd00;
  color: #3a3a3a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 23px;
}

.custom-alert-box .icon.success,
.react-confirm-alert-button-group button:first-child {
  background: #00bfa5;
  color: #fff;
  min-width: 55px;
}

.custom-alert-box .icon.error,
.react-confirm-alert-button-group button:last-child {
  background: #f27474;
  color: #fff;
}

.react-confirm-alert-blur {
  -webkit-filter: initial !important;
  filter: initial !important;
  filter: initial !important;
  -webkit-filter: initial !important;
}

.react-confirm-alert-overlay {
  z-index: 9999;
  background: rgba(255, 255, 255, 0.7);
}
.react-confirm-alert-body > h1 {
  font-size: 14px;
  margin-bottom: 7px;
}

.react-confirm-alert-body {
  font-size: 16px;
}

body.react-confirm-alert-body-element {
  padding-right: 17px;
}

.c-sidebar .c-sidebar-brand img {
  height: 35px;
}

.page-wrapper table td .square-image {
  width: 50px;
  height: 50px;
}

.page-wrapper table td .square-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.custom-file-area {
  position: relative;
}

.custome-file-drop-box-error {
  color: #f00;
  font-size: 90%;
  margin-top: 4px;
}

.image-file-preview-area {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image-file-preview-image {
  width: 70px;
  height: 70px;
  background: none;
  border: 2px dashed #ddd;
  padding: 0;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-file-preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-file-drop-box-message-text-extra {
  font-size: 90%;
  margin-top: 3px;
}

.input-group .react-datepicker-wrapper {
  display: inline-flex;
  flex: 1;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  transform: translate(20px, 0px) !important;
}
.react-datepicker-popper {
  z-index: 10;
}
.input-form,
.input-form form {
  display: flex;
}

.input-form form .form-control {
  max-width: 50px;
  margin-right: 8px;
}

.custom-radio .custom-control-label {
  cursor: pointer;
}

.table-action {
  display: flex;
  align-items: center;
}

.price .text-strike {
  text-decoration: line-through;
  font-size: 82%;
}
.status-form {
  display: flex;
}

.table-action .btn {
  height: 32px;
}

.react-tagsinput {
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 0;
  padding-right: 5px;
}
.react-tagsinput > span {
  display: block;
}
.react-tagsinput.react-tagsinput--focused {
  border: 1px solid #1f87cd;
}
.react-tagsinput-tag {
  background-color: #1f87cd;
  border: 1px solid #187cbf;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  margin-bottom: 0;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 0;
  font-family: inherit;
}
input.react-tagsinput-input {
  height: 36px;
  margin: 0;
  padding: 0.375rem 0.75rem;
  color: #495057;
  font-family: inherit;
  width: auto;
}
.h-1 {
  height: 1px;
}

.has-validation.valid .react-tagsinput,
.has-validation.valid .jodit-container:not(.jodit_inline) {
  border-color: #2eb85c !important;
}

.has-validation.error .react-tagsinput,
.has-validation.error .jodit-container:not(.jodit_inline) {
  border-color: #c71818 !important;
}

.product-choice-group {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.table-responsive td .online {
  width: 7px;
  height: 7px;
  display: inline-flex;
  margin-left: 6px;
  background: #2eb85c;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(46, 184, 92, 0.3);
}

.notification-toast {
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 21, 0.2);
}

.notification-icon {
  width: 70px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-icon .c-icon {
  width: 35px !important;
  height: 35px !important;
}

.notification-message {
  border-left: 1px solid rgba(0, 0, 21, 0.05);
  width: calc(100% - 70px);
}

.notification-icon.success {
  color: #2eb85c;
}

.notification-icon.error {
  color: #f94040;
}

.error-message-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 50px;
}

.message-icon img {
  width: 80px;
}

.message-text {
  margin-top: 20px;
  font-size: 20px;
  color: #999;
}

.react-confirm-alert-button-group > button > a {
  display: flex;
  width: 55px;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.react-confirm-alert-button-group > button {
  position: relative;
}

.map-info-area .list-group-item {
  height: 36px;
  padding: 7px 12px;
  border-radius: 2px;
}

.location-map {
  height: 400px;
  position: relative;
  background: #eee;
}

.infowindow-image a {
  display: flex;
  width: 100%;
  height: 100%;
}

.infowindow-area {
  display: flex;
}

.infowindow-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.infowindow-image {
  width: 60px;
  height: 60px;
  background: #ddd;
  margin-right: 10px;
  overflow: hidden;
}

.infowindow-details h4 {
  font-size: 18px;
}

.c-sidebar .sidebar-nav-title {
  color: rgba(255, 255, 255, 0.6);
  padding: 0.75rem 1rem;
  margin-top: 1rem;
  font-size: 80%;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.3s;
  list-style: none;
}

.c-sidebar .sidebar-nav-title .c-sidebar-nav-item,
.c-sidebar .sidebar-nav-title .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-title .c-sidebar-nav-item,
.c-sidebar .c-sidebar-nav-title .c-sidebar-nav-link {
  padding: 0;
  cursor: default;
  pointer-events: none;
}

.autocomplete-area {
  position: relative;
  z-index: 1;
}

.autocomplete-area .loading {
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  background: #fff;
  border: 1px solid #d8dbe0;
  border-radius: 3px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.autocomplete-dropdown-container {
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #d8dbe0;
  border-radius: 3px;
  font-size: 13px;
  padding: 5px 0;
  display: none;
}

.autocomplete-area
  input[aria-expanded="true"]
  + .autocomplete-dropdown-container {
  display: block;
}

.autocomplete-dropdown-container .suggestion-item {
  padding: 3px 10px;
}

.form-control:focus {
  box-shadow: 0 0 0 0;
}

.h-50vh {
  min-height: 50vh;
}
.status-block {
  display: flex;
  align-items: center;
}
.status-block strong {
  margin-right: 8px;
}

.c-sidebar .sidebar-nav-title .c-sidebar-nav-link {
  color: #37e3ff;
  font-weight: 700;
}

.mnh-100 {
  min-height: 100px;
}

.notification {
  min-width: 180px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1.25rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  color: #4f5d73;
}
.notification-icon img {
  max-height: 50px;
  width: auto;
}

.notification-icon {
  margin-bottom: 10px;
}

a.btn-link {
  cursor: pointer !important;
  color: #321fdb !important;
  text-decoration: underline;
}
a.btn-link:hover {
  cursor: pointer !important;
  color: #2212ad !important;
  text-decoration: underline;
}

.table-body-text-nowrap tbody {
  white-space: nowrap;
}
.table-filter-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.table-filter-actions button {
  margin-left: 8px;
}